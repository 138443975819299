/* You can add global styles to this file, and also import other style files */
@import "color";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
:root {
  --border-color: #eaeaea;
  --white: #ffffff;
  --black: #000000;
  --primary-color: #ef5357;
  --danger-color: #ff004d;
  --textWhite: #ffffff;
  --textBlack: #000000;
  --colorHover: #fafbfe;
  --colorText: #646777;
  --colorBorder: #eff1f5;
  --textColor: #444444;
  --smallTextColor: #999999;
  --bgColor: #eeeeee;
  --primaryDark: #ce484c;
  --yellowColor: #f6be5b;
}
* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--colorText) $borderColor;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: $borderColor;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--colorText);
  border-radius: 20px;
  border: 3px solid $borderColor;
}
html, body { height: 100%}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.icons{
  width: fit-content;
  align-items: center;
  padding: 7px 12px;
  div{
    display: block;
    padding: 0 7px;
    color: $smallTextColor;
    line-height: 0.1;
    opacity: 0.8;
  }
  mat-icon{
    cursor: pointer;
    &:hover {
      color: $primaryColor;
    }
  }
}
.container-fluid{
  @media screen and (min-width: 1200px){
    max-width: 90%;
    margin: 0 auto;
  }
}

//color picker

.color-picker {
  border: 0 !important;
  border-radius: 8px;
  box-shadow: 0 0 25px 13px rgb(0 0 0 / 10%);
  .arrow.arrow-bottom-right {
    border-color: rgba(0,0,0,0) #fffbfb rgba(0,0,0,0) rgba(0,0,0,0) !important;
  }
  .cursor {
    border: dotted 2px !important;
    box-shadow: 0 0 15px 10px rgb(0 0 0 / 10%);
  }
  .saturation-lightness {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .selected-color {
    border-radius: 8px !important;
    box-shadow: 0 0 10px 10px rgb(0 0 0 / 10%);
    border: 0 !important;
    padding: 0 !important;
  }
  .box {
    input {
      border: #e5e4e4 solid 1px;
      border-radius: 8px;
      height: 37px;
    }
  }
}
.ngx-mat-timepicker-toggle svg {
  fill: #757575;
}
.c-p{
  cursor: pointer;
}

mat-spinner{
  width: 30px !important;
  height: 20px !important;
  svg {
    width: 30px !important;
    height: 20px !important;
  }
}
.mat-button-base.mat-raised-button{
  mat-spinner{
    svg {
      circle{
        stroke: $whiteColor !important;
      }
    }
  }
}
.bg-gray{
  background-color: $bgColor;
  margin-top: 30px;
}
.object-fit-cover {
  object-fit: cover;
}
@import "assets/scss/mat-overwrite";
@import "assets/scss/notification";
