
$borderColor: var(--border-color);
$whiteColor: var(--white);
$blackColor: var(--black);
$primaryColor: var(--primary-color);
$primaryColorLight: var( --bgColor);
$dangerColor: var(--danger-color);
$textWhite: var(--textWhite);
$textBlack: var(--textBlack);
$textColor: var(--textColor);
$smallTextColor: var(--smallTextColor);
$bgColor: var(--bgColor);
$primaryDark: var(--primaryDark);
$yellowColor: var(--yellowColor);
$infoColor: #00b7ff;
$gradient: var(--primary-color);
$greenColor: #30BCB1;
$inputBgColor: #ffffff;
