@import "color";
.notifier__container-list{
  list-style-type: none;
  padding: 0;
  margin: 0;
  .notifier__notification{
    padding: 15px;
    box-shadow: 0 0 15px 10px rgb(0 0 0 / 10%);
    p{
      margin-bottom: 0;
      margin-right: 15px;
    }
    .notifier__notification-button{
      background-color: transparent;
      border: 0;
    }
  }
  .notifier__notification--error {
    background-color: $dangerColor;
    p{
      color: $whiteColor;
    }
    .notifier__notification-button{
      svg {
        fill: $whiteColor;
      }
    }
  }
  .notifier__notification--success{
    background-color: $greenColor;
    p{
      color: $whiteColor;
    }
    .notifier__notification-button{
      svg {
        fill: $whiteColor;
      }
    }
  }
  .notifier__notification--Warning{
    background-color: $yellowColor;
    p{
      color: $whiteColor;
    }
    .notifier__notification-button{
      svg {
        fill: $whiteColor;
      }
    }
  }
  .notifier__notification--info{
    background-color: $infoColor;
    p{
      color: $whiteColor;
    }
    .notifier__notification-button{
      svg {
        fill: $whiteColor;
      }
    }
  }
}
