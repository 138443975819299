@import "color";
@import '~@angular/material/theming';
@include mat-core();

$md-themecolor: (
  50 : $primaryColor,
  100 : $primaryColor,
  200 : $primaryColor,
  300 : $primaryColor,
  400 : $primaryColor,
  500 : $primaryColor,
  600 : $primaryColor,
  700 : $primaryColor,
  800 : $primaryColor,
  900 : $primaryColor,
  A100 : $primaryColor,
  A200 : $primaryColor,
  A400 : $primaryColor,
  A700 : $primaryColor,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$cex-app-primary: mat-palette($md-themecolor);
$cex-app-accent: mat-palette($md-themecolor);
$cex-app-theme: mat-light-theme($cex-app-primary, $cex-app-accent);

// Include the default theme styles.
@include angular-material-theme($cex-app-theme);


.mat-form-field-wrapper{
  padding-bottom: 0;
  margin: 0 !important;
  .mat-form-field-infix{
    border-top: 0;
  }
  .mat-form-field-outline-start, .mat-form-field-outline-end{
    border-radius: 25px !important;
  }
}
.mat-form-field-subscript-wrapper{
  display: none;
}
button.mat-primary{
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  width: fit-content;
  padding: 5px 14px;
  height: 43px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  //.mat-button-wrapper{
  //  color: $textWhite;
  //}
}
button.mat-warn{
  text-align: center;
  border-radius: 50px;
  float: left;
  cursor: pointer;
  width: fit-content;
  padding: 5px 10px;
  height: 43px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}


//tabs overwrite
.mat-tab-label-container{
  overflow: inherit !important;
}
.mat-tab-header{
  border-bottom: 0;
  overflow: inherit !important;
  width: fit-content;
  .mat-tab-labels {
    background: $bgColor;
    border-radius: 20px;
    margin: 0;
    list-style: none;
    display: flex;
    padding-left: 0;
    .mat-tab-label{
      &:first-child, &:last-child {
        padding: 0 8px;
      }
      height: auto;
      min-height: 36px;
      opacity: 1;
      min-width: 0;
      border-radius: 25px;
      .mat-tab-label-content{
        font-size: 14px;
        font-weight: 400;
        color: $smallTextColor;
        span{
          border: 1px solid $smallTextColor;
          border-radius: 20px;
          padding: 0 5px;
          font-size: 12px;
          position: relative;
          margin-left: 10px;
          line-height: 1.5;
        }
      }
      &.mat-tab-label-active{
        background: $gradient;
        box-shadow: 0 0 8px 7px rgba(243, 156, 6, 0.19);
        .mat-tab-label-content{
          color: $whiteColor;
          span{
            border-color: $borderColor;
          }
        }
      }
    }
  }
  .mat-ink-bar{
    display: none;
  }
}
.weekCheckBox {
  .mat-checkbox-inner-container {
    display: none;
  }
  .mat-checkbox-label {
    display: block;
    padding: 6px 10px;
    margin-right: 10px;
    border: 1px solid #eaeaea;
    min-width: 70px;
    text-align: center;
    border-radius: 100px;
    text-transform: capitalize;
  }
  &.mat-checkbox-checked{
    .mat-checkbox-label{
      background-color: $primaryColor;
    }
  }
  &:last-child{
    .mat-checkbox-label {
      margin-right: 0;
    }
  }
}
.mat-calendar-body-cell.selected
{
  background-color:$primaryColor !important;
  border-radius: 50%
}
.drop-calendar
{
  width:30rem
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary{
  background: $gradient;
}
.mat-stroked-button:not(.mat-button-disabled){
  border-color: $primaryColor;
  box-shadow: 0 0 0 0 !important;
}
#logs-table{
  margin: 0 -15px;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
  background-color: #ffffff;
}
.mat-dialog-container {
  display: block;
  padding: 0 !important;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}
.dialog-content {
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}
.ngx-mat-timepicker-dialog{
  .mat-dialog-container{
    padding: 0 24px 24px 24px !important;
    overflow: hidden !important;

  }
}
.custom-modalBox {
    mat-dialog-container {
      background-color: #f3f5f9;
      .modal-header{
        background-color: transparent;
      }
    }
}
